$(window).on("load", function () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    $("body").addClass("ios");
  } else {
    $("body").addClass("web");
  }
});

$(document).ready(function () {
  var ll = new LazyLoad({
    elements_selector: ".lazyload",
  });

  $(window).scroll(function () {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("fixed");
    } else {
      $("header").removeClass("fixed");
    }
  });

  if ($(window).scrollTop() > 50) {
    $("header").addClass("fixed");
  } else {
    $("header").removeClass("fixed");
  }

  $.fn.setCursorPosition = function (pos) {
    if ($(this).get(0).setSelectionRange) {
      $(this).get(0).setSelectionRange(pos, pos);
    } else if ($(this).get(0).createTextRange) {
      var range = $(this).get(0).createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  };

  $(".input-phone")
    .mask("+7 999 999 99 99", { autoclear: false })
    .on("click", function () {
      if ($(this).val() === "+7 ___ ___ __ __") {
        $(this).setCursorPosition(3);
      }
    });

  $(".menu-trigger").on("click", function (event) {
    if (!$("body").hasClass("menu-open")) {
      event.preventDefault();
      $("body").addClass("menu-open");
      //$('html').css('overflow', 'hidden');
      $("body").css("overflow", "hidden");
      $(".sidebar").addClass("active");
      $(".menu-trigger").addClass("is-active");
    } else {
      $("body").removeClass("menu-open");
      $(".sidebar").removeClass("active");
      $(".menu-trigger").removeClass("is-active");
      //$('html').css('overflow', '');
      $("body").css("overflow", "");
    }
    return false;
  });

  $(document).bind("click touchstart", function (event) {
    if ($("body").hasClass("menu-open")) {
      if ($(event.target).closest(".sidebar, .menu-trigger").length) return;
      $("body").removeClass("menu-open");
      $(".sidebar").removeClass("active");
      $(".menu-trigger").removeClass("is-active");
      $("body").css("overflow", "");
      event.stopPropagation();
    }
  });

  $(".scroll").click(function () {
    var target = $(this.hash);
    if (target.length) {
      $("body").removeClass("menu-open");
      $(".sidebar").removeClass("active");
      $(".menu-trigger").removeClass("is-active");
      $("body").css("overflow", "");

      var tt = target.offset().top - $("#header").innerHeight();
      $("html, body").animate(
        {
          scrollTop: tt,
        },
        1500
      );
      return false;
    }
  });

  $(".tabs-nav__item").click(function () {
    var tab_id = $(this).attr("data-tab");
    $(this).parent(".tabs-nav").find(".tabs-nav__item").removeClass("tabs-nav__item_active");
    $(this).addClass("tabs-nav__item_active");
    $(this).parents(".tabs").find(".tabs-content__item").removeClass("tabs-content__item_active");
    $(this)
      .parents(".tabs")
      .find('.tabs-content__item[data-tab="' + tab_id + '"]')
      .addClass("tabs-content__item_active");
  });

  $(".tabs-content__title").click(function () {
    var tab_id = $(this).attr("data-tab");
    $('.tabs-nav__item[data-tab="' + tab_id + '"]').click();
  });

  $(".form-application").each(function () {
    $(this).validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        element.parents(".y-col").append(error);
      },
      rules: {
        name: "required",
        phone: "required",
        agree: "required",
      },
      messages: {
        name: "",
        phone: "",
        agree: "",
      },
      submitHandler: function (form) {
        $.fancybox.close();
        $.fancybox.open({
          src: "#popup-thank",
          type: "inline",
          touch: false,
          closeExisting: true,
          autoFocus: false,
        });
      },
    });
  });

  $(".form-application-case").each(function () {
    $(this).validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        element.parents(".y-col").append(error);
      },
      rules: {
        name: "required",
        phone: "required",
        agree: "required",
      },
      messages: {
        name: "",
        phone: "",
        agree: "",
      },
      submitHandler: function (form) {
        $.fancybox.close();
        $.fancybox.open({
          src: "#popup-case-thank",
          type: "inline",
          touch: false,
          closeExisting: true,
          autoFocus: false,
        });
      },
    });
  });

  $(".form-application-2").each(function () {
    $(this).validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        element.parents(".y-col").append(error);
      },
      rules: {
        name: "required",
        phone: "required",
        email: {
          required: true,
          email: true,
        },
        agree: "required",
      },
      messages: {
        name: "",
        phone: "",
        email: "",
        agree: "",
      },
      submitHandler: function (form) {
        $.fancybox.close();
        $.fancybox.open({
          src: "#popup-thank",
          type: "inline",
          touch: false,
          closeExisting: true,
          autoFocus: false,
        });
      },
    });
  });

  $(".form-audit").each(function () {
    $(this).validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        element.parents(".y-col").append(error);
      },
      rules: {
        address: "required",
        name: "required",
        phone: "required",
        email: {
          required: true,
          email: true,
        },
        agree: "required",
      },
      messages: {
        address: "",
        name: "",
        phone: "",
        email: "",
        agree: "",
      },
      submitHandler: function (form) {
        $.fancybox.close();
        $.fancybox.open({
          src: "#popup-thank",
          type: "inline",
          touch: false,
          closeExisting: true,
          autoFocus: false,
        });
      },
    });
  });

  $(".js-toggle-price").click(function () {
    $(".table-price-wrapper").slideToggle();
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
      $(this).text("Скрыть");
    } else {
      $(this).text("Подробнее");
    }
    return false;
  });

  /*cases slider*/
  var swiper = new Swiper(".slider-cases", {
    pagination: {
      el: ".section-cases .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".section-cases .swiper-button-next",
      prevEl: ".section-cases .swiper-button-prev",
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    loop: true,
    watchSlidesProgress: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  var ll = swiper.slides.length - 2;
  if (ll < 10) {
    ll = "0" + ll;
  }
  $(".section-cases .swiper-pagination-total").text(ll);

  $(".section-cases .swiper-container .wrapper").on("mouseenter touchstart", function (event) {
    swiper.autoplay.stop();
    $(".section-cases").addClass("swiper-paused");
    $(".section-cases").find(".swiper-pagination-bullet-active").css("-webkit-animation-play-state", "paused");
  });

  $(".section-cases .swiper-container .wrapper").bind("mouseleave touchend", function (event) {
    swiper.autoplay.start();
    $(".section-cases").removeClass("swiper-paused");
    const activeNavItem = $(".section-cases").find(".swiper-pagination-bullet-active");

    //activeNavItem.removeClass('swiper-pagination-bullet-active');

    setTimeout(() => {
      //activeNavItem.addClass('swiper-pagination-bullet-active');
      // activeNavItem.style.animation = '';
    }, 10);
    // }
    // }
  });

  swiper.on("slideChange", function () {
    var ll2 = swiper.realIndex + 1;
    if (ll2 < 10) {
      ll2 = "0" + ll2;
    }
    $(".section-cases .swiper-pagination-current").text(ll2);
    swiper.autoplay.start();
  });

  /*end cases slider*/

  /*reviews slider*/
  var swiper2 = new Swiper(".slider-reviews", {
    pagination: {
      el: ".section-reviews .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".section-reviews .swiper-button-next",
      prevEl: ".section-reviews .swiper-button-prev",
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    loop: true,
    watchSlidesProgress: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  var ll = swiper2.slides.length - 2;
  if (ll < 10) {
    ll = "0" + ll;
  }
  $(".section-reviews .swiper-pagination-total").text(ll);

  $(".section-reviews .swiper-container .wrapper").on("mouseenter touchstart", function (event) {
    swiper2.autoplay.stop();
    $(".section-reviews").addClass("swiper-paused");
    $(".section-reviews").find(".swiper-pagination-bullet-active").css("-webkit-animation-play-state", "paused");
  });

  $(".section-reviews .swiper-container .wrapper").bind("mouseleave touchend", function (event) {
    swiper2.autoplay.start();
    $(".section-reviews").removeClass("swiper-paused");
    const activeNavItem = $(".section-reviews").find(".swiper-pagination-bullet-active");

    //activeNavItem.removeClass('swiper-pagination-bullet-active');

    setTimeout(() => {
      //activeNavItem.addClass('swiper-pagination-bullet-active');
      // activeNavItem.style.animation = '';
    }, 10);
    // }
    // }
  });

  swiper2.on("slideChange", function () {
    var ll2 = swiper2.realIndex + 1;
    if (ll2 < 10) {
      ll2 = "0" + ll2;
    }
    $(".section-reviews .swiper-pagination-current").text(ll2);
    swiper2.autoplay.start();
  });
  /*end reviews slider*/

  $(".js-clients-more").click(function () {
    $(".clients .hidden").toggleClass("shown");
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
      $(this).text("Свернуть");
    } else {
      $(this).text("Показать больше");
    }
    return false;
  });

  var swiper3 = new Swiper(".block-use-instruments .swiper-container", {
    slidesPerView: 2,
    slidesPerColumn: 2,
    slidesPerGroup: 2,
    spaceBetween: 70,
    //loop:true,
    navigation: {
      nextEl: ".block-use-instruments .swiper-button-next",
      prevEl: ".block-use-instruments .swiper-button-prev",
    },
    breakpoints: {
      1024: {
        spaceBetween: 50,
        slidesPerView: 1,
        slidesPerColumn: 4,
        slidesPerGroup: 1,
      },
      767: {
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerColumn: 1,
      },
    },
  });

  var swiper4 = new Swiper(".block-use-knowledges .swiper-container", {
    slidesPerView: 2,
    slidesPerColumn: 2,
    slidesPerGroup: 2,
    spaceBetween: 70,
    observer: true,
    observeParents: true,
    //loop:true,
    navigation: {
      nextEl: ".block-use-knowledges .swiper-button-next",
      prevEl: ".block-use-knowledges .swiper-button-prev",
    },
    breakpoints: {
      1024: {
        spaceBetween: 50,
        slidesPerView: 1,
        slidesPerColumn: 4,
        slidesPerGroup: 1,
      },
      767: {
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerColumn: 1,
      },
    },
  });

  var swiper5 = new Swiper(".block-team .swiper-container", {
    spaceBetween: 60,
    slidesPerView: 3,
    loop: false,
    navigation: {
      nextEl: ".block-team .swiper-button-next",
      prevEl: ".block-team .swiper-button-prev",
    },
    breakpoints: {
      767: {
        slidesPerView: 2,
      },
      575: {
        slidesPerView: 1,
      },
    },
  });

  $("[data-fancybox]").fancybox({
    touch: false,
    closeExisting: true,
    autoFocus: false,
  });

  if ($("#map").length) {
    loadScript();
  }

  $(".js_contacts_btn").on("click", function () {
    const $btn = $(this),
      $location = $btn.closest(".contacts__location"),
      $balloon = $location.find(".balloon");

    $location.addClass("show");
    $balloon.addClass("show");
    $(document).on("click", document_click_handler);
  });

  $(".js_balloon_close").on("click", function () {
    const $btn = $(this),
      $location = $btn.closest(".contacts__location"),
      $balloon = $location.find(".balloon");

    $location.removeClass("show");
    $balloon.removeClass("show");
  });

  function document_click_handler(evt) {
    if (
      !$(evt.target).closest(".balloon").length &&
      !$(evt.target).closest(".js_contacts_btn").length &&
      !$(evt.target).is(".js_contacts_btn")
    ) {
      $(".contacts__location").removeClass("show");
      $(".balloon").removeClass("show");
      $(document).off("click", document_click_handler);
    }
  }

  $(".js_link_to_review").on("click", function (evt) {
    evt.preventDefault();

    const $link = $(this),
      review_id = $link.data("review");

    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(".slider-reviews").offset().top - 100,
      },
      2000
    );

    swiper2.slideTo(review_id);
  });

  init_custom_select();
});

function init_custom_select() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /* For each option in the original select element,
    create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
  except the current select box: */
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  /* If the user clicks anywhere outside the select box,
then close all select boxes: */
  document.addEventListener("click", closeAllSelect);
}

/* function loadScript() {
	setTimeout(function () {
		ymaps.ready(function () {
			var myMap = new ymaps.Map(
					'map',
					{
						center: [55.726315, 37.399399],
						zoom: 16,
						controls: [],
					},
					{}
				),
				myPlacemark = new ymaps.Placemark(
					myMap.getCenter(),
					{
					},
					{
						iconLayout: 'default#image',
						iconImageHref: 'img/icon-placeholder.svg',
						iconImageSize: [50, 50],
						iconImageOffset: [-25, -25],
					}
				);

			myMap.geoObjects.add(myPlacemark);
		});
	}, 2000);
} */
